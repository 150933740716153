<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { putAction } from '@/command/netTool'

export default {
  name: 'roomManage',
  data() {
    return {
      ...api.command.getState(),
      hotelId: '',
    }
  },
  mounted() {
    const { hotelId, roomId } = this.$route.query
    this.hotelId = hotelId
    api.command.getList.call(this, {
      url: '/hotelRoom/page',
      current: 1,
      paramsValue: {
        roomId,
        hotelId,
      },
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '房间名称',
          key: 'roomName',
          type: 'input',
        },
        {
          label: '',
          key: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'roomName',
          title: '房间名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl.split(',')[0]} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.roomName.length - b.roomName.length,
        },
        {
          dataIndex: 'comboFlag',
          title: '套餐',
          align: 'center',
          width: 150,
          customRender: (text) => {
            return text == '1' ? '是' : '否'
          },
        },
        {
          dataIndex: 'bedTypeName',
          title: '床型',
          align: 'left',
          width: 160,
        },
        {
          dataIndex: 'roomArea',
          title: '客房面积',
          align: 'center',
        },
        {
          dataIndex: 'addBedName',
          title: '可否加床',
          align: 'left',
        },
        // {
        //   dataIndex: 'wifiTypeName',
        //   title: '网络',
        //   align: 'left',
        //   customRender: (text, records) => {
        //     return records.broadbandName && text
        //       ? `${records.broadbandName}、${text}`
        //       : text
        //       ? text
        //       : records.broadbandName
        //   },
        // },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          align: 'center',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.upDown == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            const { roomId } = this.$route.query
            return [
              {
                display: records.upDown == '0',
                name: records.showState == '0' ? '取消隐藏' : '隐藏',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/hotelRoom/${records.showState == 0 ? 'show' : 'hide'}?id=${records.id}`,
                  })
                },
              },
              {
                name: records.upDown == 0 ? '下架' : '上架',
                display: true,
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/hotelRoom/${records.upDown == 0 ? 'downBatch' : 'upBatch'}?id=${records.id}`,
                    params: {
                      idList: [records.id],
                    },
                  })
                },
              },
              {
                name: '日历价格',
                display: true,
                onClick: () => {
                  if (records.comboFlag == 1){
                    this.$router.push(
                               `/commodityManagement/panelCalendar?productId=${records.productComboId}&productType=3&productName=${records.productComboName}`
                            )
                  }else {
                     this.$router.push(
                        `/commodityManagement/calendar?productId=${records.productId}&productType=2&skuId=${records.skuId}`
                      )
                  } 
                },
              },
              
              {
                name: '编辑',
                display: true,
                onClick: () =>
                  this.$router.push(
                    `/commodityManagement/roomManageDetail?id=${records.id}&roomId=${roomId}&hotelId=${this.hotelId}`
                  ),
              },
              {
                name: '复制',
                display: records.comboFlag != '1',
                onClick: () => {
                  putAction(`/hotelRoom/copy/${records.id}`).then((res) => {
                    this.$message.success('复制成功')
                    api.command.getList.call(this)
                  })
                },
              },
              {
                name: '删除',
                type: 'pop',
                display: records.upDown == 1,
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPut.call(this, {
                    url: `/hotelRoom/deleteBatch`,
                    params: {
                      idList: [records.id],
                    },
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getButton() {
      const { roomId } = this.$route.query
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () =>
            this.$router.push(`/commodityManagement/roomManageDetail?hotelId=${this.hotelId}&roomId=${roomId}`),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/hotelRoom/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/hotelRoom/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/hotelRoom/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
